import React from 'react';
import NumberFormat from 'react-number-format';
import { IMaskInput } from 'react-imask';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import { Props, NumberFormatType } from './types';
import Image from 'next/image';

const Input = (
  {
    error,
    success,
    label,
    name,
    placeholder,
    type = 'text',
    onChange,
    onFocus,
    onBlur,
    mask,
    format,
    className,
    value,
    disabled,
    thousandSeparator,
    prefix,
    suffix,
    tooltip,
    isOptional,
    onValueChange,
    legend,
    allowEmptyFormatting,
    isNumericString = true,
    onKeyDown,
    autoComplete,
    min,
    max,
    iconPosition,
    noMargin
  }: Props,
  ref: React.Ref<HTMLInputElement> | undefined,
) => {
  return (
    <div className={`w-full ${noMargin ? "mb-0" : "mb-6 md:mb-8"}`}>
      {label && (
        <label className="items-center text-lg font-bold text-grey-800">
          <span>
            {label}{' '}
          </span>
          {isOptional && (
            <span className="text-grey-600">&nbsp;(optional)</span>
          )}
          {tooltip && (
            <Tippy content={tooltip}>
              <span className="ml-2">
                <Image
                  width={19}
                  height={19}
                  alt="tooltip"
                  src="/icons/tooltip.svg"
                />
              </span>
            </Tippy>
          )}
          <span className="relative w-6 h-6"></span>
        </label>
      )}
      {type === 'inputmask' ? (
        <div className="relative mt-2">
          <IMaskInput
            className={`transition-opacity read-only:select-none duration-300 ease-in-out appearance-none block w-full px-6 py-5 text-lg border-2  ${
              error
                ? 'border-error-border outline-offset-2 bg-error-border bg-opacity-25 text-error-value'
                : 'border-grey-100 bg-grey-100 text-grey-800 read-only:text-grey-400 read-only:bg-grey-50 read-only:border-grey-50'
            } 
         rounded  placeholder-grey-500  outline-grey-200 ${className}`}
            placeholder={placeholder}
            type={type as NumberFormatType}
            name={name}
            onFocus={onFocus}
            onBlur={onBlur}
            mask={String(mask)}
            placeholderChar="_"
            lazy={false}
            unmask={true}
            value={value}
            inputRef={() => ref}
            readOnly={disabled}
            onAccept={(value) => onValueChange && onValueChange(String(value))}
            prepare={(str: string) => str.toUpperCase()}
            prefix={prefix}
          />
          {suffix && <div className="absolute right-5 top-4">{suffix}</div>}
          {success && (
            <div className="h-8 right-5 absolute-center-y">
              <Image
                src="/icons/success.svg"
                width={32}
                height={32}
                alt="success"
              />
            </div>
          )}
        </div>
      ) : mask || thousandSeparator ? (
        <div className="relative mt-2">
          <NumberFormat
            className={`transition-opacity read-only:select-none duration-300 ease-in-out appearance-none block w-full px-6 py-5 text-lg border-2  ${
              error
                ? 'border-error-border outline-offset-2 bg-error-border bg-opacity-25 text-error-value'
                : 'border-grey-100 bg-grey-100 text-grey-800 read-only:text-grey-400 read-only:bg-grey-50 read-only:border-grey-50'
            } 
          rounded  placeholder-grey-500  outline-grey-200 ${className}`}
            placeholder={placeholder}
            allowEmptyFormatting={allowEmptyFormatting}
            type={type as NumberFormatType}
            displayType="input"
            name={name}
            format={format}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            mask={mask}
            value={value}
            readOnly={disabled}
            getInputRef={ref}
            allowNegative={false}
            decimalScale={2}
            isNumericString={isNumericString}
            defaultValue={undefined}
            thousandSeparator={thousandSeparator}
            prefix={prefix}
            onValueChange={({ floatValue }) =>
              onValueChange && onValueChange(floatValue as number)
            }
          />
          {suffix && <div className="absolute right-5 top-5">{suffix}</div>}
          {success && (
            <div className="h-8 right-5 absolute-center-y">
              <Image
                src="/icons/success.svg"
                width={32}
                height={32}
                alt="success"
              />
            </div>
          )}
        </div>
      ) : (
        <div className={`relative mt-2 ${className}`}>
          <input
            className={`transition-opacity duration-300 ease-in-out appearance-none block w-full px-6 py-5 text-lg border-2 ${
              error
                ? 'border-error-border outline-offset-2 bg-error-border bg-opacity-25 text-error-value'
                : 'border-grey-100 bg-grey-100 text-grey-800 read-only:text-grey-400 read-only:bg-grey-50 read-only:border-grey-50'
            } 
          rounded  placeholder-grey-500  outline-grey-200 pr-12 ${className}`}
            placeholder={placeholder}
            name={name}
            type={type}
            min={min}
            max={max}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            ref={ref}
            value={value}
            readOnly={disabled}
            onKeyDown={onKeyDown}
            autoComplete={autoComplete}
          />
          {suffix && (
            <div
              className={`absolute ${
                iconPosition ? iconPosition : 'right-5 top-5'
              }`}
            >
              {suffix}
            </div>
          )}
          {success && (
            <div className="h-8 right-5 absolute-center-y">
              <Image
                src="/icons/success.svg"
                width={32}
                height={32}
                alt="success"
              />
            </div>
          )}
        </div>
      )}
      {legend}
      <div
        className={`my-2 md:my-3 h-auto text-base transition-opacity duration-300 ease-in-out transform ${
          error ? 'inline-block text-error-border opacity-100' : 'opacity-0'
        }`}
      >
        {error}
      </div>
    </div>
  );
};

export default React.forwardRef(Input);
