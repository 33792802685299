import * as yup from 'yup';
import moment from 'moment';

export const brokerDetailsSchema = yup
  .object({
    first_name: yup
      .string()
      .required('Enter your first name')
      .min(2, 'Must be minimum 2 characters')
      .max(128, 'Must be maximum 128 characters'),
    last_name: yup
      .string()
      .required('Enter your last name')
      .min(2, 'Must be minimum 2 characters')
      .max(128, 'Must be maximum 128 characters'),
    email: yup
      .string()
      .required('Enter a valid email address')
      .email('Enter a valid email address'),
    company_name: yup
      .string()
      .required('Enter your company name')
      .min(2, 'Must be minimum 2 characters')
      .max(128, 'Must be maximum 128 characters'),
    fca_number: yup
      .string()
      .test('fcaNumber', 'Must be 6 characters', (value) => {
        return !value || value.length == 6;
      })
      .optional(),
    network_name: yup
      .string()
      .test('network_name', 'Must be minimum 2 characters', (value) => {
        return !value || (!!value && value.length >= 2);
      })
      .test('network_name', 'Must be maximum 128 characters', (value) => {
        return !value || (!!value && value.length <= 128);
      })
      .optional(),
    profession: yup.string().nullable().required('Choose one'),
    type: yup.string().nullable().required('Choose one'),
    contact_number: yup
      .string()
      .required('Enter your mobile number')
      .test(
        'contact_number',
        'Enter your mobile number',
        (value) =>
          !!value?.match(
            /(((\+44)? ?(\(0\))? ?)|(0))([ |-]?[1-9]{1}[0-9]{3})([ |-]?[0-9]{6}){1}/,
          ),
      ),
  })
  .required();

export const incomeSchema = yup
  .object({
    baseSalary: yup
      .number()
      .nullable()
      .transform((v) => (Number.isNaN(v) ? null : v))
      .typeError('Enter a valid number')
      .max(1000000000, 'Base salary must be less than £1,000,000,000')
      .optional(),
    bonusIncome: yup
      .number()
      .nullable()
      .transform((v) => (Number.isNaN(v) ? null : v))
      .typeError('Enter a valid number')
      .max(1000000000, 'Bonus must be less than £1,000,000,000')
      .optional(),
    commission: yup
      .number()
      .nullable()
      .transform((v) => (Number.isNaN(v) ? null : v))
      .typeError('Enter a valid number')
      .max(1000000000, 'Commission must be less than £1,000,000,000')
      .optional(),
    overtimeIncome: yup
      .number()
      .nullable()
      .transform((v) => (Number.isNaN(v) ? null : v))
      .typeError('Enter a valid number')
      .max(1000000000, 'Overtime income must be less than £1,000,000,000')
      .optional(),
    otherIncome: yup
      .number()
      .nullable()
      .transform((v) => (Number.isNaN(v) ? null : v))
      .typeError('Enter a valid number')
      .max(1000000000, 'Other income must be less than £1,000,000,000')
      .optional(),
  })
  .test('atLeastOneValid', (value, { createError }) => {
    if (
      !(
        value.baseSalary ||
        value.bonusIncome ||
        value.commission ||
        value.overtimeIncome ||
        value.otherIncome
      )
    )
      return createError({
        path: 'customError',
        message: 'Please provide at least one income',
      });
    else return true;
  });

export const expenditureSchema = yup.object({
  personalLoans: yup
    .number()
    .nullable()
    .max(1000000000, 'Personal loans must be less than £1,000,000,000')
    .transform((value) => (isNaN(value) ? undefined : value))
    .optional(),
  vehicleLoans: yup
    .number()
    .nullable()
    .max(1000000000, 'Vehicle loans must be less than £1,000,000,000')
    .transform((value) => (isNaN(value) ? undefined : value))
    .optional(),
  studentLoans: yup
    .number()
    .nullable()
    .max(1000000000, 'Student loans must be less than £1,000,000,000')
    .transform((value) => (isNaN(value) ? undefined : value))
    .optional(),
  creditCardsLoans: yup
    .number()
    .nullable()
    .max(
      1000000000,
      'Credit cards loans income must be less than £1,000,000,000',
    )
    .transform((value) => (isNaN(value) ? undefined : value))
    .optional(),
  childcareExpenses: yup
    .number()
    .nullable()
    .max(1000000000, 'Childcare expenses must be less than £1,000,000,000')
    .transform((value) => (isNaN(value) ? undefined : value))
    .optional(),
});

export const qualifyingCriteriaSchema = yup
  .object({
    isAllowedToLiveInUK: yup.string().nullable().required('Please choose one'),
    hasCriminalRecord: yup.string().nullable().required('Please choose one'),
    isBankruptOrIVA: yup.string().nullable().required('Please choose one'),
    missed_any_payments: yup.string().nullable().required('Please choose one'),
    income_more_than_30k: yup.string().nullable().required('Please choose one'),
  })
  .required();

export const propertySchema = yup.object({
  hasLivingLocation: yup.string().nullable().required('Please choose one'),
  preferredLocation: yup
    .string()
    .nullable()
    .when('hasLivingLocation', {
      is: '1',
      then: yup
        .string()
        .nullable()
        .max(256, 'Must be maximum 256 characters')
        .required('Please type postcode or city name')
        .typeError('Please enter a valid value'),
      otherwise: yup.string().nullable().optional(),
    }),
});

export const clientDetailsSchema = yup
  .object({
    referred_name: yup
      .string()
      .required('Enter their full name')
      .min(3, 'Must be minimum 3 characters')
      .max(128, 'Must be maximum 128 characters'),
    referred_email: yup
      .string()
      .required('Enter a valid email address')
      .email('Enter a valid email address'),
    referred_phone: yup
      .string()
      .required('Enter their phone number')
      .test(
        'referred_phone',
        'Enter their phone number',
        (value) =>
          !!value?.match(
            /(((\+44)? ?(\(0\))? ?)|(0))([ |-]?[1-9]{1}[0-9]{3})([ |-]?[0-9]{6}){1}/,
          ),
      ),
  })
  .required();

export const loginSchema = yup
  .object({
    email: yup
      .string()
      .required('Enter a valid email address')
      .email('Enter a valid email address'),
  })
  .required();

export const passwordSchema = yup
  .object({
    password: yup
      .string()
      .required('This is required field')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W])(?=.{8,})/,
        'Must contain 8 characters, one uppercase, one lowercase, one number and one special case character',
      ),
    passwordRepeat: yup
      .string()
      .required('This is required field')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  .required();

export const manageAccount = yup
  .object({
    first_name: yup
      .string()
      .required('Enter your first name')
      .min(2, 'Must be minimum 2 characters')
      .max(128, 'Must be maximum 128 characters'),
    last_name: yup
      .string()
      .required('Enter your last name')
      .min(2, 'Must be minimum 2 characters')
      .max(128, 'Must be maximum 128 characters'),
    contact_number: yup
      .string()
      .required('Enter their mobile number')
      .test(
        'contact_number',
        'Enter their mobile number',
        (value) =>
          !!value?.match(
            /(((\+44)? ?(\(0\))? ?)|(0))([ |-]?[1-9]{1}[0-9]{3})([ |-]?[0-9]{6}){1}/,
          ),
      ),
    email: yup
      .string()
      .required('Enter a valid email address')
      .email('Enter a valid email address'),
    company_name: yup
      .string()
      .required('Enter your company name')
      .min(2, 'Must be minimum 2 characters')
      .max(128, 'Must be maximum 128 characters'),
  })
  .required();

export const createApplicationSchema = yup
  .object({
    first_name: yup
      .string()
      .required('Enter their first name')
      .min(2, 'Must be minimum 3 characters')
      .max(128, 'Must be maximum 128 characters'),
    last_name: yup
      .string()
      .required('Enter their last name')
      .min(2, 'Must be minimum 3 characters')
      .max(128, 'Must be maximum 128 characters'),
    mobile_number: yup
      .string()
      .required('Enter their phone number')
      .test(
        'mobile_number',
        'Enter their phone number',
        (value) =>
          !!value?.match(
            /(((\+44)? ?(\(0\))? ?)|(0))([ |-]?[1-9]{1}[0-9]{3})([ |-]?[0-9]{6}){1}/,
          ),
      ),
    email: yup
      .string()
      .required('Enter a valid email address')
      .email('Enter a valid email address'),
    date_of_birth: yup
      .string()
      .required('Enter their date of birth')
      .test('date_of_birth', 'Enter a valid date of birth', (value) => {
        return (
          moment().diff(moment(value), 'years') >= 18 &&
          moment().diff(moment(value), 'years') <= 100
        );
      }),
    secondaryApplicants: yup
      .array()
      .test(
        'emailToCheck',
        'This matches applicant one’s email. You must enter applicant two’s email here',
        function (value, { createError }) {
          if (
            value?.length !== 0 &&
            value &&
            this.parent.email.toLowerCase().trim() ===
              value[0].email.toLowerCase().trim()
          ) {
            return createError({
              path: 'customErrorEmail',
              message:
                'This matches applicant one’s email. You must enter applicant two’s email here',
            });
          } else return true;
        },
      )
      .test(
        "mobileNumberToCheck",
        "This matches applicant one’s phone number. You must enter applicant two’s phone number here",
        function (value, { createError }) {
          if (
            value?.length !== 0 &&
            value &&
            this.parent.mobile_number === value[0].mobile_number
          ) {
            return createError({
              path: "customErrorMobile",
              message:
                "This matches applicant one’s phone number. You must enter applicant two’s phone number here.",
            });
          } else return true;
        },
      )
      .of(
        yup
          .object()
          .shape({
            first_name: yup
              .string()
              .required('First name is required')
              .min(2, 'Must be minimum 2 characters')
              .max(128, 'Must be maximum 128 characters')
              .nullable(),
            last_name: yup
              .string()
              .required('Last name is required')
              .min(2, 'Must be minimum 2 characters')
              .max(128, 'Must be maximum 128 characters')
              .nullable(),
            email: yup
              .string()
              .required('Enter a valid email address')
              .email('Enter a valid email address')
              .nullable(),
            mobile_number: yup
              .string()
              .required('Enter their phone number')
              .test(
                'mobile_number',
                'Enter their phone number',
                (value) =>
                  !!value?.match(
                    /(((\+44)? ?(\(0\))? ?)|(0))([ |-]?[1-9]{1}[0-9]{3})([ |-]?[0-9]{6}){1}/,
                  ),
              ),
            relationship_with_main_applicant: yup
              .string()
              .required('Relationship with main applicant is required')
              .min(3, 'Must be minimum 3 characters')
              .max(128, 'Must be maximum 128 characters')
              .nullable(),
          })
          .nullable(),
      )
      .nullable(),
  })
  .required();

export const secondaryApplicantSchema = yup
  .object({
    first_name: yup
      .string()
      .required('Enter their first name')
      .min(2, 'Must be minimum 2 characters')
      .max(128, 'Must be maximum 128 characters'),
    last_name: yup
      .string()
      .required('Enter their last name')
      .min(2, 'Must be minimum 2 characters')
      .max(128, 'Must be maximum 128 characters'),
    mobile_number: yup
      .string()
      .required('Enter their phone number')
      .test(
        'mobile_number',
        'Enter their phone number',
        (value) =>
          !!value?.match(
            /(((\+44)? ?(\(0\))? ?)|(0))([ |-]?[1-9]{1}[0-9]{3})([ |-]?[0-9]{6}){1}/,
          ),
      ),
    email: yup
      .string()
      .required('Enter a valid email address')
      .email('Enter a valid email address'),
    date_of_birth: yup
      .string()
      .required('Enter their date of birth')
      .test('date_of_birth', 'Enter a valid date of birth', (value) => {
        return (
          moment().diff(moment(value), 'years') >= 18 &&
          moment().diff(moment(value), 'years') <= 100
        );
      }),
    relationship_with_main_applicant: yup
      .string()
      .required('Relationship with main applicant is required')
      .min(3, 'Must be minimum 3 characters')
      .max(128, 'Must be maximum 128 characters')
      .nullable(),
  })
  .required();
