import { FC } from "react";
import Image from "next/image";

import { Props } from "./types";

const Button: FC<Props> = ({
  children,
  className,
  variant = "primary",
  prefix = null,
  suffix = null,
  isLoading = false,
  disabled = false,
  ...props
}) => (
  <button
    className={`h-[60px] mb-4 sm:mb-0 w-full sm:w-auto flex justify-center items-center px-6 py-5 text-lg font-bold text-left rounded ${className} btn-${variant}`}
    disabled={isLoading || disabled}
    {...props}
  >
    <div className="flex items-center justify-center">
      {isLoading && (
        <div className="absolute flex">
          <Image src="/icons/throbber.gif" width={24} height={24} alt="throbber" />
        </div>
      )}
      <div className={`flex items-center ${isLoading && "invisible"}`}>
        {prefix && <span className="mr-3">{prefix}</span>}
        {children}
        {suffix && <span className="ml-3">{suffix}</span>}
      </div>
    </div>
  </button>
);

export default Button;
